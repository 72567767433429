<template>
  <div id="base">
    <LoadingScreen :loading="loading_screen" />
    <div class="container is-fullhd" v-if="!loading_screen">
      <div class="columns">
        <div class="column pr-0 is-2 is-hidden-touch">
          <SideBar :userData="userData" :menus="menus" :acct-type="account_type"></SideBar>
        </div>
        <div class="column pl-0">
          <NavBar :userData="userData" @openDropdown="open_menu = true" @logout="logout"></NavBar>
          <modal-menu :show="open_menu" :userData="userData" :menus="menus" @close="open_menu = false"
            @logout="logout"></modal-menu>

          <div class="mainBase">
            <router-view class="p-4" :tokenData="tokenData" :userData="userData" :loaded="loaded" :branchs="branchs"
              :nodes="nodes" :devices="devices" :device_log="DeviceLogSort" :account_type="account_type" :client="client"
              :connected="connected" :topic="topic" @get_branch="get_branch" @get_node="get_node" @get_device="get_device"
              @get_device_log="get_device_log" @connectMQTT="connectMQTT" @subscribeMQTT="subscribeMQTT"
              @update_account_type="update_account_type" ref="view" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen'
import SideBar from '@/components/SideBar'
import NavBar from '@/components/NavBar'
import ModalMenu from '@/components/Modal/Menu'

var mqtt = require('mqtt');

// let TOPIC = "tnj-electronics/localhost/";
// let TOPIC = "tnj-electronics/powermeter999/";
let TOPIC = [
  "tnj-electronics/powermeter999/",
  "tnj-electronics/TNJ_SM/",
  "tnj-electronics/TNJ_SC/",
  "tnj-electronics/TNJ_SI/"
];
let HOST = [
  "ws://broker.hivemq.com:8000/mqtt",
  "ws://119.59.99.133:9001"
]

export default {
  name: 'MemberBase',
  components: { LoadingScreen, SideBar, NavBar, ModalMenu },
  data() {
    return {
      tokenData: {},
      userData: {},
      loaded: {},
      branchs: [],
      nodes: [],
      devices: [],
      device_log: [],
      account_type: 2,
      menus_array: [
        [],
        [
          {
            title: "My Profile",
            name: "MemberProfile",
            icon: "fas fa-user",
            href: "/member/profile",
            bg: "is-info",
          },
          {
            title: "Mornitor",
            name: "MemberMornitor",
            icon: "fas fa-desktop",
            href: "/member/mornitor",
            bg: "is-warning",
          },
          {
            title: "Daily Energy",
            name: "MemberDailyEnergy",
            icon: "fas fa-bolt",
            href: "/member/daily_energy",
            bg: "is-info",
          },
          {
            title: "Report",
            name: "MemberReport",
            icon: "fas fa-chart-bar",
            href: "/member/report",
            bg: "is-info",
          },
          {
            title: "Devices Log",
            name: "MemberLog",
            icon: "fas fa-file-alt",
            href: "/member/log",
            bg: "is-info",
          }
        ],
        [
          {
            title: "My Profile",
            name: "MemberProfile",
            icon: "fas fa-user",
            href: "/member/profile",
            bg: "is-info",
          },
          {
            title: "Mornitor",
            name: "MemberMornitor",
            icon: "fas fa-desktop",
            href: "/member/mornitor",
            bg: "is-warning",
          },
        ],
        [
          {
            title: "My Profile",
            name: "MemberProfile",
            icon: "fas fa-user",
            href: "/member/profile",
            bg: "is-info",
          },
          {
            title: "Mornitor",
            name: "MemberMornitor",
            icon: "fas fa-desktop",
            href: "/member/mornitor",
            bg: "is-warning",
          },
        ],
      ],
      open_menu: false,
      client: null,
      connected: false,
      subscribed: [],
      topic: TOPIC,
    }
  },
  created() {
    // setTimeout(()=> this.loading_screen = false, 2000);
    this.tokenData = this.LocalStorage("_TNJ_PowerMeter_tokenData_");
  },
  mounted() {
    this.get_userData();
  },
  methods: {
    async get_userData() {
      var url = this.api + 'user/get_userData/' + this.secret;
      var data = new FormData();
      data.set("email", this.tokenData.email);
      data.set("token", this.tokenData.token);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            this.userData = response.data.data;
            this.get_count_device_type(this.userData);

            if (this.isAdmin(this.userData)) this.redirect("/admin");
          } else {
            this.redirect("/");
          }
        })
        .catch((error) => {
          console.error(error);
          this.$swal("", "", "warning");
        })
        .then(() => {
          this.check_login(this.userData);
        })
    },
    async get_count_device_type(userData) {
      var url = this.api + 'user/get_count_device_type/' + this.secret;
      var data = new FormData();
      data.set("id", userData.id);
      data.set("email", userData.email);
      data.set("token", userData.token);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            let type1 = 0;
            let type2 = 0;
            let type3 = 0;
            response.data.data.forEach((e) => {
              let type = parseInt(e.type);
              if (type == 1) type1 = parseInt(e.count);
              else if (type == 2) type2 = parseInt(e.count);
              else if (type == 3) type3 = parseInt(e.count);
            });

            if (type3 > 0) this.account_type = 3;
            if (type1 > 0) this.account_type = 1;
            if (type2 > 0) this.account_type = 2;
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    async get_branch() {
      var url = this.api + 'user/get_branch/' + this.secret;
      var data = new FormData();
      data.set("u_id", this.userData.id);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            this.branchs = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
          this.$swal("", "get_branch()", "warning");
        })
        .then(() => {
          this.loaded.get_branch = true;
        })
    },
    async get_node(b_id) {
      var url = this.api + 'user/get_node/' + this.secret;
      var data = new FormData();
      data.set("b_id", b_id);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            // if(!this.nodes.length){
            //   this.nodes = response.data.data;
            // }else{
            //   // response.data.data.forEach((e) => {
            //   //   this.nodes.push(e);
            //   // });
            //   response.data.data.forEach((e) => {
            //     let index = this.nodes.findIndex(x => x.id == e.id);
            //     if(index==-1)this.nodes.push(e);
            //   });
            // }
            response.data.data.forEach((e) => {
              e.on = null;
              e.connect = null;
              e.turning_on = false;
              e.turning_off = false;

              let index = this.nodes.findIndex(x => x.id == e.id);
              if (index == -1) this.nodes.push(e);
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$swal("", "get_node()", "warning");
        })
        .then(() => {
          if (!this.loaded.get_node) {
            this.loaded.get_node = [];
          }
          this.loaded.get_node.push(b_id);
        })
    },
    async get_device(n_id) {
      var url = this.api + 'user/get_device/' + this.secret;
      var data = new FormData();
      data.set("n_id", n_id);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            if (!this.devices.length) {
              this.devices = response.data.data;
            } else {
              // response.data.data.forEach((e) => {
              //   this.devices.push(e);
              // });
              response.data.data.forEach((e) => {
                let index = this.devices.findIndex(x => x.id == e.id);
                if (index == -1) this.devices.push(e);
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.$swal("", "get_device()", "warning");
        })
        .then(() => {
          if (!this.loaded.get_device) {
            this.loaded.get_device = [];
          }
          this.loaded.get_device.push(n_id);
        })
    },
    async get_device_log(d_id, type) {
      // var url = this.api+'user/get_device_log/'+this.secret;

      var url = "";
      if (parseInt(type) == 1) url = this.api + 'user/get_device_log/' + this.secret;
      if (parseInt(type) == 2) url = this.api + 'user/get_inverter_log/' + this.secret;

      var data = new FormData();
      data.set("d_id", d_id);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            if (!this.device_log.length) {
              this.device_log = response.data.data;
            } else {
              // response.data.data.forEach((e) => {
              //   this.device_log.push(e);
              // });
              response.data.data.forEach((e) => {
                let index = this.device_log.findIndex(x => x.id == e.id);
                if (index == -1) this.device_log.push(e);
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.$swal("", "get_device_log()", "warning");
        })
        .then(() => {
          if (!this.loaded.get_device_log) {
            this.loaded.get_device_log = [];
          }
          this.loaded.get_device_log.push(d_id);
        })
    },
    currentPage(menu) {
      return this.currentRouteName == menu.name;
    },
    connectMQTT(mac, type) {
      let self = this;

      HOST.forEach(host => {
        this.client = mqtt.connect(host);
        this.client.on('connect', function () {
          self.connected = true;
          console.log('Connected: %csuccessfully', 'background: green; color: white');

          self.subscribeMQTT(mac, type);
        });
        this.client.on('error', function (error) {
          self.connected = false;
          console.error(error);
        });
        this.client.on('message', function (topic, message) {
          // console.log(topic,message.toString());
          self.validate_value(topic, message.toString());
        });
      });
    },
    subscribeMQTT(mac, type = 0) {
      if (this.client) {
        const INDEX = this.subscribed.map((x) => x).indexOf(mac);
        if (INDEX !== -1) {
          console.log(`%cAlready subscribe to ${mac}`, 'background: green; color: white');
        } else {
          this.subscribed.push(mac);
          // this.client.subscribe(TOPIC+mac);
          // this.client.subscribe(TOPIC+mac+"/CONTROL");
          // this.client.subscribe(TOPIC+mac+"/ONLINE");
          // console.log(TOPIC+mac);

          this.client.subscribe(`${TOPIC[0]}${mac}`);
          this.client.subscribe(`${TOPIC[0]}${mac}/CONTROL`);
          this.client.subscribe(`${TOPIC[0]}${mac}/ONLINE`);
          console.log(TOPIC[type] + mac);
          this.client.subscribe(`${TOPIC[type]}${mac}`);
          this.client.subscribe(`${TOPIC[type]}${mac}/CONTROL`);
          this.client.subscribe(`${TOPIC[type]}${mac}/ONLINE`);

          // ***********************************************************************************************
          // let topic = this.topic+mac+"/CONTROL";
          // let payload = `is_online=${this.secret}`;
          // this.client.publish(topic, payload, { qos: 0, retain: false }, (error) => {
          //   if (!error) console.log(`Published: ${topic} %c${payload}`,'background: orange; color: black');
          // })
          // ***********************************************************************************************
        }
      }
    },
    validate_value(topic, message) {
      let MAC = topic.split('/').reverse()[1];
      let ACTION = topic.split('/').reverse()[0];

      // console.log(MAC, ACTION);
      if (MAC == "powermeter999") MAC = ACTION;
      else if (MAC == "TNJ_SM") MAC = ACTION;
      else if (MAC == "TNJ_SC") MAC = ACTION;
      else if (MAC == "TNJ_SI") MAC = ACTION;
      // console.log(MAC, ACTION);

      const INDEX = this.nodes.map((n) => n.mac).indexOf(MAC);
      if (INDEX == -1) {
        console.error("Unknow topic: ", MAC);
        return false;
      }

      switch (ACTION.toLowerCase()) {
        case "control":
          console.log("control", message);
          if (message == "on=1") this.update_status_on_off(MAC, { on: 1 }, 1, 0); //สั่งเปิด
          if (message == "off=1") this.update_status_on_off(MAC, { on: 0 }, 0, 1); //สั่งปิด
          break;
        case "online":
          console.log("online", message);
          if (message == "ON_INVERTER") {
            this.update_status_on_off(MAC, { on: 1 }, 0, 0);
          } else if (message == "OFF_INVERTER") {
            this.update_status_on_off(MAC, { on: 0 }, 0, 0);
          } else {
            var mySubString = message.substring(
              message.indexOf("{") + 1,
              message.lastIndexOf("}")
            );
            console.log("online", `{${mySubString}}`);
            this.update_status_on_off(MAC, `{${mySubString}}`);
          }
          break;
        default:
      }

      // if(typeof message !==" object") return false;
      // // alert(typeof message);
      try {
        let json = JSON.parse(message);
        // console.log( json );

        switch (true) {
          case (!json.Key):
            console.error("Not Found Key!!!");
            break;
          case (json.Key !== "6c6756c82eca0bdd4c1340fa24d4f6081e906aeb"):
            console.error("Invalid Key", json.Key);
            break;
          case (typeof json.ID == "undefined"):
            console.error("Invalid Value: ID", json);
            break;
          case (typeof json.Current_a == "undefined"):
            console.error("Invalid Value: Current_a", json);
            break;
          case (typeof json.Current_b == "undefined"):
            console.error("Invalid Value: Current_b", json);
            break;
          case (typeof json.Current_c == "undefined"):
            console.error("Invalid Value: Current_c", json);
            break;
          case (typeof json.Imp_Energy == "undefined"):
            console.error("Invalid Value: Imp_Energy", json);
            break;
          case (typeof json.Exp_Energy == "undefined"):
            console.error("Invalid Value: Exp_Energy", json);
            break;
          case (typeof json.Freq == "undefined"):
            console.error("Invalid Value: Freq", json);
            break;
          case (typeof json.PF_t == "undefined"):
            console.error("Invalid Value: PF_t", json);
            break;
          case (typeof json.PF_a == "undefined"):
            console.error("Invalid Value: PF_a", json);
            break;
          case (typeof json.PF_b == "undefined"):
            console.error("Invalid Value: PF_b", json);
            break;
          case (typeof json.PF_c == "undefined"):
            console.error("Invalid Value: PF_c", json);
            break;
          case (typeof json.Power_t == "undefined"):
            console.error("Invalid Value: Power_t", json);
            break;
          case (typeof json.Power_a == "undefined"):
            console.error("Invalid Value: Power_a", json);
            break;
          case (typeof json.Power_b == "undefined"):
            console.error("Invalid Value: Power_b", json);
            break;
          case (typeof json.Power_c == "undefined"):
            console.error("Invalid Value: Power_c", json);
            break;
          case (typeof json.Volt_a == "undefined"):
            console.error("Invalid Value: Volt_a", json);
            break;
          case (typeof json.Volt_b == "undefined"):
            console.error("Invalid Value: Volt_b", json);
            break;
          case (typeof json.Volt_c == "undefined"):
            console.error("Invalid Value: Volt_c", json);
            break;
          default:
            // console.log(json)
            this.add_device_log(json);
        }
      } catch (e) {
        console.error(message);
        console.error(e);
      }
    },
    add_device_log(json) {
      const INDEX = this.devices.map((d) => parseInt(d.number)).indexOf(parseInt(json.ID));
      if (INDEX !== -1) {
        const ThisDevice = this.devices[INDEX];
        if (!parseInt(ThisDevice.enable)) {
          console.error("This device is disable!", ThisDevice.number);
          return false;
        }
        const d_id = ThisDevice.id;

        Object.keys(json).forEach((key) => {
          json[key] = json[key].toString();
        });
        json.d_id = d_id;
        json.Number = json.ID;
        json.date_now = Date.now();
        var d = new Date(parseInt(json.date_now)),
          dformat = [d.getFullYear(),
          d.getMonth() + 1,
          d.getDate()].join('-') + ' ' +
            [this.leftPad(d.getHours(), 2),
            this.leftPad(d.getMinutes(), 2),
            this.leftPad(d.getSeconds(), 2)].join(':');
        json.created = dformat;

        // console.log("add_device_log",json);
        this.device_log.unshift(json);
      }
    },
    update_status_on_off(mac, message, turning_on = null, turning_off = null) {
      console.log("update_status_on_off", mac, message, turning_on, turning_off);

      // if(typeof message !==" object") return false;
      // alert(typeof message);
      // let json = JSON.parse(message);
      let json = (typeof message !== "object") ? JSON.parse(message) : message;
      // console.log("update_status_on_off", mac, json.on);

      const INDEX = this.nodes.map((n) => n.mac).indexOf(mac);
      if (INDEX !== -1) {
        this.nodes[INDEX].on = (json.on == 1) ? true : false;
        if (turning_on !== null) this.nodes[INDEX].turning_on = (turning_on == 1) ? true : false;
        if (turning_off !== null) this.nodes[INDEX].turning_off = (turning_off == 1) ? true : false;
      }
    },
    update_account_type(n) {
      this.account_type = n;
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    DeviceLogSort() {
      let DeviceLogSort = this.device_log;
      if (DeviceLogSort > 1) {
        DeviceLogSort = DeviceLogSort.sort((a, b) => {
          // if(parseInt(a.id)>parseInt(b.id)) return -1
          if (parseInt(a.date_now) > parseInt(b.date_now)) return -1
        })
      }
      return DeviceLogSort;
    },
    menus() {
      return this.menus_array[this.account_type];
    }
  }
}
</script>

<style scoped="">
#base {
  background: #e9e9e9 !important;
}

.mainBase {
  min-height: 900px;
  background: #e1e1e1;
}
</style>
